export const ALL_BOOTCAMPS = 'allBootcamps';
export const MY_BOOTCAMPS = 'myBootcamps';

export const GS_BUCKET_NAME = 'bootcamper';

// ROLES
export const ADMIN = 'admin';
export const PUBLISHER = 'publisher';
export const USER = 'user';

export const CAREERS = [
  'Web Development',
  'Mobile Development',
  'UI/UX',
  'Data Science',
  'Business',
  'Android Development',
  'Other',
];
